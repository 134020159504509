// use in pair with chkboxes controller
// features:
// - hide checkboxes if there are no actions to perform
// - set colspan on checkboxes preview cell (from chkboxes controller) based on columns count

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    if (this.emptyActions) {
      this._hideChkboxes()
    }
    this._setColspan()
  }

  get emptyActions () {
    return !this.element
      .querySelector('tfoot tr th:last-of-type .action-btns')
      ?.childNodes?.length
  }

  _hideChkboxes () {
    ['custom-control', 'custom-control-label'].forEach(n =>
      this.element.querySelectorAll(`tr > th:first-of-type .${n}`)
        .forEach(el => el.classList.remove(n))
    )
  }

  _setColspan () {
    const colsCount = this.element.querySelectorAll('thead tr:first-of-type th').length
    this.element
      .querySelector('[data-chkboxes-target=preview]')
      ?.setAttribute('colspan', colsCount - 1)
  }
}
