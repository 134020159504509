// Original checkboxes-actions-controller from 2019
// features:
// - display selected checkbox values into `previewTarget` element
// - toggle all checkboxes

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "checkbox",  // checkboxes with values
    "preview", // element for displaying checkboxes values
    "hideable", // container element for preview and actions; hidden if values are empty
  ]

  connect() {
    this.values = new Set();
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        this.addCheckbox(checkbox)
      }
    })
  }

  onToggleAll(event) {
    this.checkboxTargets.forEach(checkbox => {
      this.toggleCheckbox(checkbox, event.target.checked)
    });
  }

  onToggleCheckbox(event) {
    this.toggleCheckbox(event.target, event.target.checked)
  }

  toggleCheckbox(checkbox, toggle) {
    if (toggle)
      this.addCheckbox(checkbox)
    else
      this.removeCheckbox(checkbox)
  }

  toggleHideable() {
    if (this.hasHideableTarget) {
      this.hideableTargets.forEach(el => el.classList.toggle('d-none', !this.values.size))
    }
  }

  addCheckbox(checkbox) {
    checkbox.checked = true;
    this.values.add(checkbox.value);
    this.displayValues();
    this.toggleHideable();
  }

  removeCheckbox(checkbox) {
    checkbox.checked = false;
    this.values.delete(checkbox.value);
    this.displayValues();
    this.toggleHideable();
  }

  displayValues() {
    if (this.previewTarget) {
      const content = Array.from(this.values).join(', ')
      this.previewTarget.innerText = content;
    }
  }
}
