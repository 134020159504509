import Flatpickr from "stimulus-flatpickr";

// import "flatpickr/dist/flatpickr.css";

// import the translation files and create a translation mapping
import { Polish } from "flatpickr/dist/l10n/pl.js";
import { english } from "flatpickr/dist/l10n/default.js";

export default class extends Flatpickr {
  locales = {
    pl: Polish,
    en: english
  };

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: this.locales[this.data.get("locale")],
      allowInput: true,
    };

    super.connect();
  }
}
