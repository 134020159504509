import { Controller } from '@hotwired/stimulus'

// synchronize selected inputs` values
// with the target link's searchParams or form inputs
export default class extends Controller {
  static values = {
    selector: String, // can be set here or as param to `sync` method
    scoped: Boolean, // selector scoped to controller element, false by default
  }

  connect () {
    this.selScope = this.scopedValue ? this.element : document
  }

  sync (e) {
    const { selScope, selector, paramName } = this._parseParams(e)
    // console.log(selector, selScope)
    const handler = this._create_handler(e.target)

    selScope.forEach((scopeEl) => {
      const condition = e.params.condition
        ? !!scopeEl.querySelector(e.params.condition)
        : true
      if (condition) {
        scopeEl.querySelectorAll(selector).forEach((el) => {
          const name = el.dataset.syncedParamsName || paramName || el.name
          handler(name, el.value)
        })
      }
    })
    // e.preventDefault()
  }

  // parse `selector` and `selScope` params
  _parseParams(e) {
    return {
      // opt, defaults to controller element or document
      // scope can return multiple elements → selector is run for each scope matching condition
      selScope: e.params.scope
        ? document.querySelectorAll(e.params.scope)
        : [this.selScope],
      // opt, override `selectorValue`
      selector: e.params.selector || this.selectorValue,
      // opt, name can be set here or on a selected element as data-synced-params-name attribute
      paramName: e.params.paramName
    }
  }

  // for links attaches search params
  // for forms appends hidden inputs
  _create_handler(node) {
    return node.nodeName === 'FORM' || node["form"]
    ? (name, value) => {
      const newInput = document.createElement('input')
      Object.assign(newInput, {
        type: 'hidden',
        name: name,
        value: value,
        autocomplete: 'off'
      })
      node.append(newInput)
    }
    : node.nodeName === 'A'
    ? (name, value) => {
      const newURL = new URL(node.href);
      newURL.searchParams.append(name, value)
      node.href = newURL
    }
    : console.error("unsupported element", e.target.nodeName)
  }
}
